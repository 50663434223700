import React, {useEffect, useState} from "react";
import _uniqBy from "lodash.uniqby";

import {CDSNotification} from "@ciscodesignsystems/cds-react-notification";

import useToast from "hooks/useToast";
import usePersistedBannerStates from "hooks/usePersistedBannerState";
import tdrClient from "apis/tdr/tdrClient";
import useConfig from "hooks/useConfig";
import useAuth from "hooks/useAuth";
import {getAllFeatureFlags} from "utils/featureFlags";

import {
  mapEventTypeToStatus,
  filterItemsByRegion,
  filterByNotClosedInStorage,
  filterItemsByScope,
} from "./utils";

import type {BannerItem, BannerStates} from "types/bannerState";
import type {BannerRequestDefault} from "./types";

interface MaybeMultiBannerProps {
  bannerStates: BannerStates[];
  banners: BannerItem[];
  setBannerStates: (newBannerStates: BannerStates[]) => void;
}

const bannerRequestInit: BannerRequestDefault = {
  isLoading: false,
  error: null,
  data: null,
};

const onCloseBanner =
  (
    bannerStates: BannerStates[],
    banner: BannerItem,
    setBannerStates: (state: BannerStates[]) => void,
  ) =>
  () => {
    const updatedData = _uniqBy(
      [
        {
          id: banner.id,
          closed: true,
          end_timestamp: banner.end_timestamp,
        },
        ...bannerStates,
      ],
      "id",
    );
    setBannerStates(updatedData);
  };

const MaybeMultiBanner = ({
  bannerStates,
  banners,
  setBannerStates,
}: MaybeMultiBannerProps) => {
  return bannerStates.some(
    (banner: BannerStates) => banner.closed === false,
  ) ? (
    <CDSNotification
      style={{margin: 20}}
      aria-label="Notification"
      grouped
      onDismissAll={() => {
        const updatedData = bannerStates.map((storedBanner: BannerStates) => ({
          ...storedBanner,
          closed: true,
        }));
        setBannerStates(updatedData);
      }}
      product
      title="You have multiple notifications"
    >
      <CDSNotification.Group>
        {banners
          ?.filter(filterByNotClosedInStorage(bannerStates))
          ?.map((banner) => (
            <CDSNotification.Item
              key={banner?.id}
              title={banner?.title}
              status={mapEventTypeToStatus(banner?.event_type)}
              onClose={onCloseBanner(bannerStates, banner, setBannerStates)}
            >
              {banner?.status_updates?.slice(-1)[0]?.public_description}
            </CDSNotification.Item>
          ))}
      </CDSNotification.Group>
    </CDSNotification>
  ) : null;
};

const SitewideBanner = () => {
  const useTdrStageApi = getAllFeatureFlags()?.useTdrStageApi;
  const {config} = useConfig();
  const {region} = config;
  const {user} = useAuth();
  const {enqueueToast} = useToast();

  const [bannerStates, setBannerStates] = usePersistedBannerStates();
  const [bannerRequest, setBannerRequest] =
    useState<BannerRequestDefault>(bannerRequestInit);

  useEffect(
    () => {
      setBannerRequest({
        ...bannerRequestInit,
        isLoading: true,
      });

      const upComingMaintenanceQuery = `event_type eq 'maintenance' and start_timestamp gt '${new Date().toISOString()}'`;
      const activeEventsQuery = `end_timestamp eq null and start_timestamp le '${new Date().toISOString()}'`;

      tdrClient
        .get(
          `/api/intermediary/tdr/${useTdrStageApi ? "stage" : "prod"}?query=(${upComingMaintenanceQuery}) or (${activeEventsQuery})`,
        )
        .then(({data}) => {
          const maybeFilteredItems = data?.items
            .filter(filterItemsByRegion(region))
            .filter(filterItemsByScope(user));

          if (bannerStates?.length === 0) {
            setBannerStates(
              maybeFilteredItems.map((banner: BannerItem) => ({
                id: banner.id,
                end_timestamp: banner.end_timestamp,
                closed: false,
              })),
            );
          } else {
            const maybeNewBanners = maybeFilteredItems.map(
              (banner: BannerItem) => ({
                id: banner.id,
                end_timestamp: banner.end_timestamp,
                closed: false,
              }),
            );

            const updatedData = _uniqBy(
              [...bannerStates, ...maybeNewBanners],
              "id",
            );

            setBannerStates(updatedData);
          }

          setBannerRequest({
            ...bannerRequestInit,
            isLoading: false,
            data: {
              ...data,
              items: maybeFilteredItems.map((bannerData: BannerItem) => ({
                ...bannerData,
                closed:
                  bannerStates.find(
                    (banner: BannerStates) => banner.id === bannerData.id,
                  )?.closed ?? false,
              })),
            },
          });
        })
        .catch((error) => {
          if (useTdrStageApi) {
            enqueueToast({
              id: `${Date.now()}${Math.random()}`,
              title: "VPN Needed for Banner Notifications",
              description:
                "To get Maintenance and Incident banner notifications from the TDR Public Events Staging API, please connect to the Cisco VPN.",
              status: "info",
              timeout: -1, // user must dismiss
            });
          }

          setBannerStates([
            ...bannerStates,
            {id: "1", closed: false, end_timestamp: ""},
          ]);

          setBannerRequest({
            ...bannerRequestInit,
            isLoading: false,
            error,
            data: {
              start: 0,
              stop: 0,
              total: 1,
              items: [
                {
                  id: "1",
                  event_type: "performance",
                  product_id: "",
                  scheduled_end_timestamp: null,
                  creation_timestamp: "",
                  start_timestamp: "",
                  end_timestamp: "",
                  status_updates: [
                    {
                      id: "1",
                      creation_timestamp: "",
                      status_type: "investigating",
                      event_id: "1",
                      public_description:
                        "Unable to obtain banner notifications. If this continues, contact Cisco Support.",
                    },
                  ],
                },
              ],
            },
          });
        });
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [useTdrStageApi, region, user],
  );

  const banners =
    bannerRequest.data?.items?.filter(
      filterByNotClosedInStorage(bannerStates),
    ) ?? [];

  if (banners?.length === 0 || bannerRequest.isLoading) return null;

  return banners?.length > 0 && banners?.length <= 3 ? (
    <div style={{margin: 20}}>
      {banners.map((banner) => (
        <CDSNotification
          style={{marginBottom: "1rem"}}
          banner
          key={banner.id}
          title={banner.title}
          status={mapEventTypeToStatus(banner.event_type)}
          onClose={onCloseBanner(bannerStates, banner, setBannerStates)}
        >
          {banner?.status_updates?.slice(-1)[0].public_description}
        </CDSNotification>
      ))}
    </div>
  ) : (
    <MaybeMultiBanner
      bannerStates={bannerStates}
      banners={banners}
      setBannerStates={setBannerStates}
    />
  );
};

export default SitewideBanner;
