import {useRouter} from "next/router";
import {useCallback, useEffect, useRef} from "react";

import type {NextRouter} from "next/router";

interface UseRouterSyncRemoteRef {
  syncRemoteRouter: (path: string) => void;
}

interface ShellRouterRef {
  currentRoute: string;
  replace: NextRouter["replace"];
}

/**
 * A hook for bi-directional router syncing between the shell app and the MFEs.
 *
 * syncShellRouter: Syncs the MFE route change into the shell router.
 * syncRemoteRouter: Syncs the shell route change into the MFE router.
 */
export default function useRouterSync() {
  const {asPath: currentRoute, events, replace} = useRouter();
  const remoteRef = useRef<UseRouterSyncRemoteRef>();
  const shellRouterRef = useRef<ShellRouterRef>({
    currentRoute,
    replace,
  });

  // Keep potentially mutable items needed in stable "syncShellRouter" in the "shellRouterRef"
  shellRouterRef.current = {
    currentRoute,
    replace,
  };

  /**
   * Sync the MFE route change into the local shell router (MFE -> shell sync).
   * This function is supposed to be called inside the MFA whenever it navigates.
   *
   * This function MUST have a stable reference! It is typically used in the MFE "useEffect"
   * deps array. If this function would change a reference, it would cause a MFE reflow
   * and a premature MFE -> shell sync call, which would push an old URL to the shell router,
   * causing an infinite loop.
   */
  const syncShellRouter = useCallback(
    (newRoute: string) => {
      // keep this condition, prevents one more loop
      if (newRoute !== shellRouterRef.current?.currentRoute) {
        return shellRouterRef.current?.replace(newRoute, newRoute, {
          shallow: true,
        });
      }
      return Promise.resolve(false);
    },
    [
      /**
       * THIS FUNCTION MUST HAVE A STABLE REFERENCE!
       *
       * Do not add any dependencies here!
       */
    ],
  );

  /**
   * Sync the shell route change into the MFE router (shell -> MFE sync).
   *
   * This useEffect only registers to the next router events to sync the MFE router.
   */
  useEffect(() => {
    function syncMfRouter(path: string) {
      remoteRef.current?.syncRemoteRouter(path);
    }

    /**
     * We need to hook on "routeChangeComplete" to sync MFE router properly.
     * "routeChangeComplete" fires AFTER the new URL is pushed to browser history.
     * After that, the MFE router can sync with the new URL.
     *
     * MFE router needs to use "replace", to update itself (and the browser history
     * is overwritten with the same URL).
     */
    events.on("routeChangeComplete", syncMfRouter);

    return () => {
      events.off("routeChangeComplete", syncMfRouter);
    };
  }, [
    // just react on "events" change
    events,
  ]);

  return {
    remoteRef,
    syncShellRouter,
  };
}
