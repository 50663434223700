// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

import en from "locales/en/common";

// eslint-disable-next-line import/no-named-as-default-member
void i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    resources: {
      en: {common: en},
    },
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
      useSuspense: true,
    },
  });

export default i18n;
