import {useTranslation} from "react-i18next";

import Error from "components/errors/Error";
import useNav from "hooks/useNav";

export default function ErrorFallback() {
  const {navTree, activeTopNav} = useNav();
  const {t} = useTranslation();
  const appTitle =
    navTree.find((route) => route.href === activeTopNav)?.title ?? "The app";
  const message = `${appTitle} encountered an error.`;

  return (
    <Error
      title={message}
      buttonText={t("app.error.refresh")}
      onButtonClick={() => window.location.reload()}
    />
  );
}
