import {Lightning, User, Users, Globe} from "phosphor-react";
import {useTranslation} from "react-i18next";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";
import {
  CDSTenantMenuRoot,
  CDSTenantMenuRow,
  CDSTenantMenuDivider,
} from "@ciscodesignsystems/cds-react-header";
import {CDSToggle} from "@ciscodesignsystems/cds-react-toggle";

import useAuth from "hooks/useAuth";
import useConfig from "hooks/useConfig";
import useTheme from "hooks/useTheme";
import {trackThemeToggle} from "utils/telemetry";

import SystemStatus from "./SystemStatus";
import TenantSwitcher from "./TenantSwitcher";
import ThemeIcon from "./ThemeIcon";

import styles from "./TenantMenu.module.scss";

export default function TenantMenu() {
  const {
    goToLogout,
    user: {userName = "unknown user", userNick, userEmail},
    org: {name: orgName = "unknown org"},
    isSecureClient,
  } = useAuth();
  const {config} = useConfig();
  const {regionName} = config;
  const {theme, toggleTheme} = useTheme();
  const {t} = useTranslation();

  const handleThemeToggle = () => {
    const isCurrentThemeDark = theme === "dusk";

    if (isCurrentThemeDark) {
      trackThemeToggle("dark", "light");
    } else {
      trackThemeToggle("light", "dark");
    }

    toggleTheme();
  };

  return (
    <CDSTenantMenuRoot
      sentiment="inverse"
      switcherType="combo"
      userName={userNick || userName}
      tenantName={orgName}
    >
      <CDSTenantMenuRow
        leftGutter={
          <User className="grey-text-color" weight="regular" size={24} />
        }
        rightGutter={
          <CDSButton
            variant="tertiary"
            style={{padding: "8px 12px"}} // match magna-react tertiary button for xdr
            onClick={goToLogout}
          >
            {t("app.tenantMenu.logout")}
          </CDSButton>
        }
      >
        <p
          style={{
            margin: "0",
            textTransform: "uppercase",
            fontSize: "12px",
          }}
        >
          {t("app.tenantMenu.loggedInAs")}
        </p>
        <p className={styles.boldMenuFont}>{userName}</p>
        <p
          style={{
            margin: "0",
            fontSize: "14px",
            textOverflow: "ellipsis",
            maxWidth: 220,
            overflow: "hidden",
          }}
        >
          {userEmail}
        </p>
      </CDSTenantMenuRow>
      <CDSTenantMenuDivider />

      <CDSTenantMenuRow
        leftGutter={
          <Globe className="grey-text-color" weight="regular" size={24} />
        }
      >
        <p
          style={{
            margin: "0",
            textTransform: "uppercase",
            fontSize: "12px",
          }}
        >
          {t("app.tenantMenu.region")}
        </p>
        <p className={styles.boldMenuFont}>{regionName}</p>
      </CDSTenantMenuRow>
      <CDSTenantMenuDivider />
      <CDSTenantMenuRow
        style={{paddingBottom: 0}}
        leftGutter={
          <Users className="grey-text-color" weight="regular" size={24} />
        }
      >
        <p
          style={{
            margin: "0",
            textTransform: "uppercase",
            fontSize: "12px",
          }}
        >
          {!isSecureClient
            ? t("app.tenantMenu.xdrSelected")
            : t("app.tenantMenu.secureClientSelected")}
        </p>
      </CDSTenantMenuRow>
      <CDSTenantMenuRow style={{paddingTop: 0}} leftGutter="&zwnj;">
        <TenantSwitcher />
      </CDSTenantMenuRow>
      <CDSTenantMenuDivider />
      <CDSTenantMenuRow
        leftGutter={
          <Lightning className="grey-text-color" weight="regular" size={24} />
        }
        rightGutter={<SystemStatus />}
      >
        <span className={styles.boldMenuFont}>
          {t("app.tenantMenu.systemStatus")}
        </span>
      </CDSTenantMenuRow>
      <CDSTenantMenuDivider />
      <CDSTenantMenuRow
        leftGutter={<ThemeIcon />}
        rightGutter={
          <>
            <span className={styles.toggleLeft}>
              {t("app.tenantMenu.themeLight")}
            </span>
            <CDSToggle
              size="md"
              checked={theme === "dusk"}
              onChange={handleThemeToggle}
              className={styles.themeToggle}
            />
            <span className={styles.toggleRight}>
              {t("app.tenantMenu.themeDark")}
            </span>
          </>
        }
      >
        <span className={styles.boldMenuFont}>{t("app.tenantMenu.theme")}</span>
      </CDSTenantMenuRow>
    </CDSTenantMenuRoot>
  );
}
