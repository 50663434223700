import {useCallback, useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import {useTranslation} from "react-i18next";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";

import useToast from "hooks/useToast";
import {getIncidentCount} from "utils/getIncidentCount";
import {getIncidentsBySearch} from "utils/getIncidentsBySearch";
import {publish} from "eventBus/publish";

import {filteredIncidentsUrl, incidentSearchParams} from "./utils";

import styles from "./AiToast.module.scss";

export const NEW_INCIDENTS_TOAST_SEEN_KEY = "aiAssistant.newIncidentsToastSeen";

/**
 * The component displays the AI toast variant upon the Shell app's mount in order to notify the user
 * of any new incidents requiring attention.
 * To display the AI toast, this component calls an API to get the count of filtered incidents based on provided parameters,
 * and if the count is more than 0, it pushes a toast notification to the queue.
 * In case the count equals 1, it makes an additional API call to obtain an incident ID that is used to build
 * the incident detail URL for the AI toast button.
 */
const AiToastIncidents = () => {
  const [count, setCount] = useState<number>(0);
  const [incidentId, setIncidentId] = useState<string | undefined>(undefined);
  const router = useRouter();
  const {t} = useTranslation();
  const {enqueueToast, dequeueToast} = useToast();
  const toastId = useRef("");
  const newIncidentToastSeen = Boolean(
    sessionStorage.getItem(NEW_INCIDENTS_TOAST_SEEN_KEY),
  );

  const publishEvent = useCallback((count: number, id?: string) => {
    if (count === 1 && id) {
      publish({
        topic: "aiToastViewOneIncidentButtonClicked",
        payload: {
          incidentId: id,
        },
      });
    } else {
      publish({
        topic: "aiToastViewIncidentsButtonClicked",
        payload: undefined,
      });
    }
  }, []);

  const handleButtonClick = useCallback(() => {
    const incidentDetailUrl = `/incidents/${incidentId}/overview`;
    const url = count === 1 ? incidentDetailUrl : filteredIncidentsUrl;
    router.push(url, url, {shallow: true}).then(() => {
      dequeueToast(toastId.current);
      publishEvent(count, incidentId);
    });
  }, [incidentId, count, dequeueToast, publishEvent, router]);

  /**
   * Fetch the count of incidents above the priority score threshold
   */
  useEffect(() => {
    getIncidentCount(incidentSearchParams).then((count) => setCount(count));
  }, []);

  /**
   * If only one incident, fetch the incident ID
   */
  useEffect(() => {
    if (count === 1) {
      getIncidentsBySearch(incidentSearchParams, 1).then((incidents) => {
        const id = incidents[0].id;
        const shortId = id.substring(id.lastIndexOf("/") + 1);
        setIncidentId(shortId);
      });
    }
  }, [count]);

  /**
   * Display AI toast notification for all pages when the Shell app mounts.
   * The prompt should only be shown once per login session
   */
  useEffect(
    () => {
      if (!newIncidentToastSeen) {
        getIncidentCount(incidentSearchParams).then((count) => {
          if (count > 0) {
            toastId.current = enqueueToast({
              title: `${t("ai.toast.title.hasIdentified")} ${t(
                "ai.toast.title.incidentCount",
                {
                  postProcess: "interval",
                  count,
                },
              )}`,
              description: (
                <>
                  {/* Verbiage */}
                  <div className={styles.aiToastDescription}>
                    {`${t("ai.toast.description.priorityScore", {count})} ${t("ai.toast.description.recommendation")}`}
                  </div>
                  {/* Button */}
                  <div className={styles.aiToastButtonContainer}>
                    <CDSButton
                      data-event="viewIncidents"
                      variant="secondary"
                      onClick={handleButtonClick}
                      className={styles.aiToastButton}
                    >
                      {t("ai.toast.view", {
                        count,
                      })}
                    </CDSButton>
                  </div>
                </>
              ),
              status: "ai",
            });
            // Set the flag to prevent the toast from showing again. Will be cleared on logout.
            sessionStorage.setItem(NEW_INCIDENTS_TOAST_SEEN_KEY, "true");
          }
        });
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      // run only on mount
    ],
  );

  return null;
};

export default AiToastIncidents;
