import * as Bowser from "bowser";

import {publishTelemetry} from "./telemetryQueue";

function getBrowser() {
  return Bowser.parse(window.navigator.userAgent);
}

function getAspectRatio(width: number, height: number) {
  const ratio = width / height;
  const name = ratio < 0.8 ? "portrait" : ratio > 1.2 ? "landscape" : "square";
  return name;
}

function percentage(num: number) {
  return `${Math.round(num * 100)}%`;
}

/**
 * INDIVIDUAL TRACKING FUNCTIONS BELOW = = = = = = = = = =
 */

export function trackClick(event: MouseEvent) {
  if (event.target instanceof Element) {
    publishTelemetry({
      service: "xdr-ui.shell.user-tracker",
      description: "XDR click",
      metadata: {
        action: "click",
        pathname: window.location.pathname,
        "target-href": event.target.getAttribute("href"),
        "target-innerText":
          "innerText" in event.target ? event.target.innerText : undefined, // does not exist eg. on SVG elements
        "target-class": event.target.getAttribute("class"),
        "target-aria-label": event.target.getAttribute("aria-label"),
        "closest-id": event.target.closest("[id]")?.id,
        "closest-data-testid": event.target
          .closest("[data-testid]")
          ?.getAttribute("data-testid"),
      },
    });
  }
}

function getViewportProps() {
  return {
    "screen-width": screen.availWidth, // indexed as text
    "screen-width-int": screen.availWidth, // indexed as integer
    "screen-height": screen.availHeight, // indexed as text
    "screen-height-int": screen.availHeight, // indexed as integer
    "screen-aspect-ratio": getAspectRatio(
      screen.availWidth,
      screen.availHeight,
    ),
    "viewport-width": innerWidth, // indexed as text
    "viewport-width-int": innerWidth, // indexed as integer
    "viewport-height": innerHeight, // indexed as text
    "viewport-height-int": innerHeight, // indexed as integer
    "viewport-aspect-ratio": getAspectRatio(innerWidth, innerHeight),
    "viewport-screen-area": percentage(
      (innerWidth / screen.availWidth) * (innerHeight / screen.availHeight),
    ),
    "browser-screen-area": percentage(
      (outerWidth / screen.availWidth) * (outerHeight / screen.availHeight),
    ),
  };
}

function getSystemThemeProps() {
  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

  return {
    "system-theme": systemTheme,
  };
}

function getAppliedThemeProps() {
  const appliedTheme = document.documentElement.dataset.colorScheme;

  // When the applied theme is "normal", the app is still initializing.
  return {
    "applied-theme": appliedTheme === "normal" ? null : appliedTheme,
  };
}

interface NavigationExtraParams {
  url: string;
  prevUrl?: string;
  firstLoad?: "true" | "false";
}

export function trackNavigation(params: NavigationExtraParams) {
  const appliedThemeProps = getAppliedThemeProps();

  // When the applied theme cannot be determined, then wait to send
  // any theme data at all.
  const themeProps =
    appliedThemeProps["applied-theme"] === null
      ? {}
      : {
          ...appliedThemeProps,
          ...getSystemThemeProps(),
        };

  publishTelemetry({
    service: "xdr-ui.shell.routes",
    description: "XDR navigation complete",
    metadata: {
      action: "navigate",
      ...params,
      ...getViewportProps(),
      ...themeProps,
    },
  });
}

export function trackViewportSize() {
  publishTelemetry({
    service: "xdr-ui.shell.viewport",
    description: "Viewport resize",
    metadata: {
      action: "resize",
      url: location.href,
      ...getViewportProps(),
    },
  });
}

export function trackLogin() {
  const {browser, os, platform} = getBrowser();
  publishTelemetry({
    service: "xdr-ui.shell.session",
    description: "User logged in",
    metadata: {
      action: "login",
      "browser-name": browser.name,
      "browser-version": browser.version,
      "os-name": os.name,
      "os-version": os.version,
      "platform-type": platform.type,
      connection: navigator.connection?.effectiveType,
      "browser-language": navigator.language,
      "browser-languages": navigator.languages.join(","),
      "max-touch-points": navigator.maxTouchPoints,
    },
  });
}

export function trackLogout() {
  const {browser, os, platform} = getBrowser();
  publishTelemetry({
    service: "xdr-ui.shell.session",
    description: "User initiated logout",
    metadata: {
      action: "logout",
      "browser-name": browser.name,
      "browser-version": browser.version,
      "os-name": os.name,
      "os-version": os.version,
      "platform-type": platform.type,
    },
  });
}

/**
 *
 * @param from the theme that is changing
 * @param to the theme to be applied
 */
export function trackThemeToggle(from: string, to: string) {
  publishTelemetry({
    service: "xdr-ui.shell.theme",
    description: "User changed theme",
    metadata: {
      action: "toggle",
      from,
      to,
      url: location.href,
      ...getSystemThemeProps(),
    },
  });
}
