export default {
  administration: {
    apiClients: {
      title: "API Clients",
    },
    integrations: {
      title: "Integrations",
    },
    myAccount: {
      title: "My Account",
    },
    notifications: {
      title: "Notifications",
    },
    onPremisesAppliances: {
      title: "On-Premises Appliances",
    },
    playbooks: {
      title: "Playbooks",
    },
    title: "Administration",
    users: {
      title: "Users",
    },
  },
  ai: {
    onboarding: {
      cancel: "Cancel",
      introduction: "Introducing the Cisco AI Assistant",
      letsGo: "Let‘s Go",
      quickTour:
        "Take a quick tour to learn how the AI Assistant works and to get tips for using it to support your work in XDR.",
    },
    toast: {
      description: {
        priorityScore: "The incident has a minimum priority score of 700.",
        priorityScore_other:
          "The incidents have a minimum priority score of 700.",
        recommendation:
          "Immediate investigation and mitigation is recommended.",
      },
      title: {
        hasIdentified: "The AI Assistant has identified",
        incidentCount:
          "(1)[1 incident];(2-99)[{{count}} incidents];(100-inf)[100+ incidents]",
      },
      view: "View Incident",
      view_other: "View Incidents",
    },
  },
  app: {
    comingSoon: {
      description:
        "We‘re working hard to bring you a new and improved experience, and this page isn‘t quite ready yet. Stay tuned for updates!",
      title: "Page under construction",
    },
    error: {
      code404: "404 Error!",
      code500: "500 Error!",
      notFound: "The requested page was not found.",
      refresh: "Refresh",
      serverError:
        "The server encountered an error and could not complete your request.",
    },
    // This is for the page header title
    name: {
      secureClient: "Secure Client",
      xdr: "XDR",
    },
    navMenu: {
      beta: "Beta",
    },
    roleChange: {
      info: "<p>We have detected that your role has been changed from:<br /><b>{{oldRole}}</b> to <b>{{newRole}}</b></p><p>You will need to refresh your session. This may require you to log in again.</p>",
    },
    tenantMenu: {
      lastLoginAt: "last login {{lastLoginDate}}",
      loggedInAs: "Logged in as",
      logout: "Logout",
      region: "Region",
      searchOrganizations: "Search organizations",
      secureClientSelected: "Organization Selected",
      switch: "Switch",
      systemStatus: "System Status",
      theme: "Theme",
      themeDark: "Dark",
      themeLight: "Light",
      xdrSelected: "XDR Organization Selected",
    },
    // This is for the HTML <title> tag
    title: {
      secureClient: "Cisco Secure Client Management",
      xdr: "Cisco XDR",
    },
  },
  assets: {
    devices: {
      title: "Devices",
    },
    sources: {
      title: "Sources",
    },
    title: "Assets",
    users: {
      title: "Users",
    },
  },
  automate: {
    accountKeys: {
      title: "Account Keys",
    },
    advanced: {
      title: "Advanced",
    },
    exchange: {
      title: "Exchange",
    },
    runs: {
      title: "Runs",
    },
    targets: {
      title: "Targets",
    },
    tasks: {
      title: "Tasks",
    },
    title: "Automate",
    triggers: {
      title: "Triggers",
    },
    variables: {
      title: "Variables",
    },
    workflows: {
      title: "Workflows",
    },
  },
  clientManagement: {
    auditLogs: {
      title: "Audit Logs",
    },
    clients: {
      title: "Clients",
    },
    deployments: {
      title: "Deployments",
    },
    deviceEvents: {
      title: "Device Events",
    },
    profiles: {
      title: "Profiles",
    },
    title: "Client Management",
  },
  controlCenter: {
    dashboards: {
      title: "Dashboards",
    },
    mitreCoverageMap: {
      title: "MITRE Coverage Map",
    },
    title: "Control Center",
  },
  incidents: {
    title: "Incidents",
  },
  intelligence: {
    events: {
      title: "Events",
    },
    feeds: {
      title: "Feeds",
    },
    indicators: {
      title: "Indicators",
    },
    judgments: {
      title: "Judgments",
    },
    title: "Intelligence",
  },
  investigate: {
    title: "Investigate",
  },
  roles: {
    admin: "Administrator",
    sat: "Security Analyst",
    user: "Incident Responder",
  },
} as const;
